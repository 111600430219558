import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import axios from 'axios';
import General from 'helper/general.js';
import Loader from 'components/loader.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import MaterialToolTip from '@material-ui/core/Tooltip';

import AnalyticsStyle from 'assets/jss/analytics.jsx';


class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      archive_count: null,
      order_analytics: null,
      loading: true
    }
  }

  componentDidMount() {
    axios({
      method: 'GET',
      url: `${General.serverURL}address_validator/bc_api/admin_order_analytics?shop=${General.getBrandData().name}`
    })
    .then((result) => {
      this.setState({ 
        archive_count: result.data.archive_count,
        order_analytics: result.data.order_analytics,
        loading: false
      })
    })
    .catch((err) => 'fetch count error')
  }


  render() {
    const { classes } = this.props;

    if(this.state.loading)
      return <Loader />;
    else if(this.state.order_analytics.length === 0)
      return (
        <GridContainer justify='center'>
          <GridItem xs={10} sm={10} md={6}>
            <Card>
              <CardBody>
                <h2 className={classes.center}>No orders processed yet - come back later</h2>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    else
      return (  
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}><b>{this.state.archive_count + _.sumBy(this.state.order_analytics, 'order_count')}</b><MaterialToolTip title='Order count updated every day at midnight Pacific Standard Time.' placement='top'><sup style={{ cursor: 'pointer' }}>*</sup></MaterialToolTip> total orders processed</h3>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={12} style={{ overflowX: 'auto' }}>
                  <h4>Orders processed in the last 3 months:</h4>
                  <LineChart
                    width={500}
                    height={400}
                    data={this.state.order_analytics}
                    margin={{ top: 0, right: 0, left: 0, bottom: 10 }}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='order_date' />
                    <YAxis />
                    <Tooltip />
                    <Line type='monotone' dataKey='order_count' stroke='#8884d8' />
                  </LineChart>
                </GridItem>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
  }
}

Analytics.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(AnalyticsStyle)(Analytics);