import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Snackbar } from '@material-ui/core/';


class Instructions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message_open: false,
      message: ''
    }
  }

  componentDidMount() {
    
  }

  copyCode() {
    const codeBox = document.getElementById('codeBox');

    let textarea = document.createElement('textarea');
    textarea.id = 'temp_element';
    textarea.style.height = 0;
    codeBox.appendChild(textarea);
    textarea.value = codeBox.innerText;
  
    document.querySelector('#temp_element').select();
    document.execCommand('copy');
    codeBox.removeChild(textarea);

    this.setState({ message_open: true, message: 'Code snippet copied to clipboard' });
  }


  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={1000}
          onClose={() => this.setState({ message_open: false })}
          open={this.state.message_open}
          message={<span id='message-id'>{this.state.message}</span>}
        />
        <Dialog
          open={this.props.open}
          onClose={() => this.props.softClose()}
          aria-labelledby='instructions-title'
          aria-describedby='instructions-content'
          fullWidth={true}
          maxWidth='md'
        >
          <DialogTitle id='instructions-title'>Only insert code snippet if app is not loading during checkout</DialogTitle>
          <DialogContent style={{ textAlign: 'center' }}>
            <p><b>Step 1:</b> Go to Storefront > Script Manager. <a href='https://support.bigcommerce.com/s/article/Using-Script-Manager?language=en_US' target='_blank' rel='noopener noreferrer'>See this page for more information</a></p>
            <p><b>Step 2:</b> Click <i>Create Script</i>. Name: <i>Address Validator</i>, Location: <i>Footer</i>, Pages: <i>Checkout</i>, Category: <i>Essential</i>, Type: <i>Script</i></p>
            <p><b>Step 3:</b> Copy the code below and paste in the script contents box. Remember to click <i>Save</i> on the bottom right when you are done.</p>
            <pre id='codeBox' style={{ whiteSpace: 'pre-wrap', backgroundColor: '#f5f5f5', borderRadius: 5, border: '2px solid black', fontSize: 14, textAlign: 'center', padding: 5 }}>{`<script>;setTimeout(() => {if(window.location.href.indexOf('/checkout') !== -1 && typeof(AddressValidatorAppLoaded) === 'undefined'){var a=document.createElement("script");a.src="https://d3ox8tdk614ykk.cloudfront.net/bc_script.js",document.head.appendChild(a)}}, 1000);</script>`}</pre>
            <Button
              variant='contained'
              color='primary'
              onClick={() => this.copyCode()}
              style={{ marginBottom: 15 }}
            >
              Copy code to clipboard
            </Button>
            <p>You are done! Click the <i>Done</i> button below.</p>
            <Button variant='contained' color='primary' onClick={() => this.props.hardClose()}>Done</Button>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
};


export default Instructions;