import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

import sidebarStyle from 'assets/jss/sidebar.jsx';


class Sidebar extends React.Component {
  render() {
    const activeRoute = (routeName) => this.props.location.pathname.indexOf(routeName) !== -1;
    
    const { brand, classes, closeDrawer, handleDrawerToggle, open, routes } = this.props;

    let links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect)
            return null;

          const listItemClasses = classNames({ [' ' + classes.orange]: activeRoute(prop.path) });
          const whiteFontClasses = classNames({ [' ' + classes.whiteFont]: activeRoute(prop.path) });

          return (
            <NavLink
              to={prop.path}
              className={classes.item}
              activeClassName='active'
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                  {typeof prop.icon === 'string' ? <Icon>{prop.icon}</Icon> : <prop.icon />}
                </ListItemIcon>
                <ListItemText
                  primary={prop.sidebarName}
                  className={classes.itemText + whiteFontClasses}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          );
        })}
      </List>
    );
    let sidebarTitle = (
      <div className={classes.logo}>
        <a href={'http://' + brand.domain} target='_blank' rel='noopener noreferrer' className={classes.logoLink} onClick={() => closeDrawer() }>
          {brand.store_name}
        </a>
      </div>
    );
    return (
      <div>
        <Hidden mdUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor='right'
            open={open}
            classes={{ paper: classes.drawerPaper }}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }} // Better open performance on mobile.
          >
            {sidebarTitle}
            <div className={classes.sidebarWrapper}>
              {links}
            </div>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation='css'>
          <Drawer
            anchor='left'
            variant='permanent'
            open
            classes={{ paper: classes.drawerPaper }}
          >
            {sidebarTitle}
            <div className={classes.sidebarWrapper}>{links}</div>
          </Drawer>
        </Hidden>
      </div>
    );
  }
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
