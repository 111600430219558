import { cardCategoryWhite, cardTitleWhite } from 'assets/jss/theme.jsx';


const PostalCodeChecksStyle = theme => ({
  cardCategoryWhite,
  cardTitleWhite,
  cardDescription: {
    fontSize: '16px',
    marginBottom: '0px',
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  },
  formControl: {
    width: '100%'
  },
  warningBox: {
    borderRadius: '5px',
    marginTop: '10px',
    padding: '10px',
    border: '1px solid rgb(229, 148, 0)',
    color: 'rgb(229, 148, 0)',
    backgroundColor: 'rgb(255, 246, 229)'
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
});

export default PostalCodeChecksStyle;