/* eslint-disable */
import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, TextField } from '@material-ui/core/';
import _ from 'lodash';
import axios from 'axios';
import General from 'helper/general.js';
import BillingStyles from 'assets/jss/prompts.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardForm from 'components/cardForm';
import { Elements } from 'react-stripe-elements';
import InputChips from "components/inputChips";
import Snackbar from "components/snackBar";


class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      alert_type: "",
      success_message: "",
      error_message: "",
      activated: false,
      receipt_email: "",
      receipt_email_ipt: "",
      stripe_preferences_id: null,
    };

    this.updateReceiptEmail = this.updateReceiptEmail.bind(this);
    this.changeReceiptEmailState = this.changeReceiptEmailState.bind(this);
  }

  componentDidMount() {
    const brand = General.getBrandData();
    this.setState({ activated: brand.activated });

    axios({
      method: 'GET',
      url: General.serverURL + 'address_validator/bc_api/pricing',
      headers: { Authorization: `Bearer ${General.getJWT()}` },
    })
    .then((result) => this.setState({ price: result.data ? result.data.price : 'test' }));

    axios({
      method: 'GET',
      url: General.serverURL + 'address_validator/bc_api/stripe_preferences',
      headers: { Authorization: `Bearer ${General.getJWT()}` }
    })
    .then((result) => {
      if (result.data) {
        this.setState({
          receipt_email: result.data.receipt_email,
          stripe_preferences_id: result.data.id,
        });
      }
    })
  }

  updateReceiptEmail(input) {
    const { stripe_preferences_id } = this.state;

    let data = {
      receipt_email: input,
    }

    if (stripe_preferences_id) {
      data.id = stripe_preferences_id
    }

    return axios({
      method: 'PUT',
      url: General.serverURL + 'address_validator/bc_api/stripe_preference_receipt_email',
      headers: { Authorization: `Bearer ${General.getJWT()}` },
      data: data,
    })
  }

  changeReceiptEmailState(event) {
    this.setState({ receipt_email_ipt: event.target.value });

    this.updateReceiptEmail(event.target.value).then((result) => {
      this.setState({
        receipt_email: result.data.receipt_email,
        receipt_email_ipt: "",
        open: true,
        alert_type: "success",
      });
    });
  }

  render() {
    const { classes } = this.props;

    if(this.state.activated)
      return (
        <GridContainer justify='center'>
          <Snackbar
            alert_type={this.state.alert_type}
            close={() =>
              this.setState({
                open: false,
                alert_type: null,
                success_message: null,
              })
            }
            success_msg={this.state.success_message}
            error_msg={this.state.error_message}
            open={this.state.open}
          />
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <h2 style={{ margin: 0, textAlign: 'center' }}>Account has been activated.</h2>
                <p className={classes.cardDescription}>
                  You will be charged ${this.state.price} per address after the first 100 addresses.
                </p>
                <div style={{ marginTop: 20, textAlign: 'center' }}>
                  <Button variant='contained' color='primary' className={classes.button} onClick={() => this.setState({ activated: false })}>
                    Change card
                  </Button>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={10}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Change receipt email address</h3>
              </CardHeader>
              <CardBody>
                <InputChips
                    list={
                      this.state.receipt_email_ipt || this.state.receipt_email
                    }
                    label="Email address"
                    onChange={(value) => this.changeReceiptEmailState(value)}
                  />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    else
      return (
        <GridContainer justify='center'>
          <Snackbar
            alert_type={this.state.alert_type}
            close={() =>
              this.setState({
                open: false,
                alert_type: null,
                success_message: null,
              })
            }
            success_msg={this.state.success_message}
            error_msg={this.state.error_message}
            open={this.state.open}
          />
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Please input a credit card to activate account</h3>
              </CardHeader>
              <CardBody>
                <p className={classes.cardDescription}>
                  <b>The first 100 addresses are free and will work without account activation</b>.
                </p>
                <p className={classes.cardDescription}>
                  If you are happy with the trial and would like to continue with the service, please input a credit card to activate your account.
                </p>
                <p className={classes.cardDescription}>
                  You will be charged ${this.state.price} per address after the first 100 addresses.
                </p>
                <br />
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={12} md={6}>
                    <Elements>
                      <CardForm successFunc={() => this.setState({ activated: true })} />
                    </Elements>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={10}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Change receipt email address</h3>
              </CardHeader>
              <CardBody>
                <InputChips
                    list={
                      this.state.receipt_email_ipt || this.state.receipt_email
                    }
                    label="Email address"
                    onChange={(value) => this.changeReceiptEmailState(value)}
                  />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
  }
}

export default withStyles(BillingStyles)(Billing);