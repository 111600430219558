import { cardCategoryWhite, cardTitleWhite } from 'assets/jss/theme.jsx';

const analyticsStyle = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  center: {
    textAlign: 'center'
  },
  cardCategoryWhite,
  cardTitleWhite
});

export default analyticsStyle;
