import React from 'react';
import { Redirect } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { Button, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core/';
import DefaultCard from '@material-ui/core/Card';
import PostalCodeChecks from 'assets/jss/postalcodechecks.jsx';
import Loader from 'components/loader.jsx';
import axios from 'axios';
import DescriptionBox from 'components/descriptionBox.jsx';
import Snackbar from '../../components/snackBar.jsx';


class PostalCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      countries_list: null,
      country_zip_filters: [],
      ruleFormMode: null,
      showRuleForm: false,
      newCountry: '',
      newOptions: 1,
      newRules: { option_1: { length: '', rules: [] } },
      newWarning: '',
      targetRule: null,
      deleteRuleForm: false
    };
  }

  componentDidMount() {
    const brand = General.getBrandData();

    this.setState({ loading: true });
    axios({
      method: 'GET',
      url: `${General.serverURL}address_validator/bc_api/admin_country_list?shop=${General.getBrandData().name}`
    })
    .then((result) => {
      let filteredCountries = _.filter(result.data, (country) => _.map(brand.country_zip_filters, (string) => string.split(',')[0]).indexOf(country[0]) === -1);
      this.setState({ loading: false, countries_list: filteredCountries, country_zip_filters: brand.country_zip_filters || [] })
    })
    .catch((err) => 'fetch countries error')
  }

  initiateRules(count_new) {
    let rules = this.state.newRules;

    const count_current = _.keys(rules).length;

    if(count_new > count_current)
      for(let num = count_current + 1; num <= count_new; num++) {
        if(!rules['option_' + num])
          rules['option_' + num] = { length: '', rules: [] };
      }
    else
      for(let num = count_current; num > count_new; num--) {
        delete rules['option_' + num];
      }

    return rules;
  }

  updateRuleLength(index, value) {
    let rulesObj = this.state.newRules;
    let currentRules = rulesObj['option_' + index].rules;
    const currentLength = currentRules.length;

    if(value > currentLength)
      for(let num = 0; (value - currentLength) > num; num++) {
        currentRules.push('');
      }
    else
      currentRules = currentRules.slice(0, value);

    rulesObj['option_' + index] = {
      length: value,
      rules: currentRules
    }

    this.setState({ newRules: rulesObj });
  }

  updateRule(option, index, value) {
    let newFormat = this.state.newRules['option_' + option];
    newFormat.rules[index] = value;

    let updatedRulesList = this.state.newRules;
    updatedRulesList['option_' + option] = newFormat;
    
    this.setState({ newRules: updatedRulesList });
  }

  editRule(obj) {
    const parts = obj.split(',');
    const rules = parts[2].split('|');

    let newRules = {};
    for(let count = 1; count <= rules.length; count++) {
      const ruleParts = rules[count - 1].split('_');
      let rulesArray = _.map(ruleParts[1].replace(/]/g, '],').replace(/\[/g, ',[').replace(/ /g, ', ,').split(','), (data) => {
        if(data.indexOf('[') === -1 && data.indexOf(']') === -1 && data.indexOf(' ') === -1)
          return data.replace(/-/g, ',-,').split(',');
        else
          return data;
      });
      rulesArray = _.filter(_.flatten(rulesArray), (data) => data !== '');
      newRules['option_' + count] = { length: parseInt(ruleParts[0]), rules: rulesArray };
    }
      
    this.setState({ showRuleForm: true, ruleFormMode: 'edit', targetRule: obj, newCountry: [parts[0], parts[1]], newOptions: rules.length, newWarning: parts[3], newRules: newRules });
  }

  saveRule() {
    this.setState({ loading: true });

    let rulesArray = [];
    for(let count = 1; count <= this.state.newOptions; count++) {
      const obj = this.state.newRules['option_' + count];
      rulesArray.push([obj.length + '_' + obj.rules.join('')]);
    }
    
    const updatedRules = _.filter(this.state.country_zip_filters, (data) => data.indexOf(this.state.newCountry + ',') !== 0).concat([this.state.newCountry.join(','),  rulesArray.join('|'), this.state.newWarning.replace(',', '')].join(','));

    const updatedCountries = _.filter(this.state.countries_list, (country) => country !== this.state.newCountry);
    General.setBrandValue('country_zip_filters', updatedRules, (type) => this.setState({ loading: false, showRuleForm: false, newCountry: '', newOptions: 1, newRules: { option_1: { length: '', rules: [] } }, newWarning: '', countries_list: updatedCountries, country_zip_filters: updatedRules, open: true, alert_type: type }));
  }

  removeRule(rule) {
    this.setState({ loading: true });
    const updatedRules = _.filter(this.state.country_zip_filters, (entity) => entity !== rule);
    const ruleParts = rule.split(',');
    const updatedCountries = this.state.countries_list.concat([[ruleParts[0], ruleParts[1]]]);
    General.setBrandValue('country_zip_filters', updatedRules, (type) => this.setState({ targetRule: null, deleteRuleForm: false, loading: false, countries_list: updatedCountries, country_zip_filters: updatedRules, open: true, alert_type: type })); 
  }

  allowSave() {
    let valid = true;
    for(let count = 1; count <= this.state.newOptions; count++) {
      if(this.state.newRules['option_' + count].rules.length === 0 || this.state.newRules['option_' + count].rules.indexOf('') !== -1) {
        valid = false;
        break;
      }
    }
    return valid
  }

  render() {
    const { classes } = this.props;

    if(this.state.redirect)
      return <Redirect to='/address_prompts' />;
    else if(this.state.loading)
      return <Loader />;
    else
      return (
        <div>
          <Snackbar
            alert_type={this.state.alert_type}
            close={() => this.setState({ open: false, alert_type: null })}
            open={this.state.open}
          />
          <GridContainer justify='center'>
            <GridItem xs={12} sm={12} md={10}>
              <DescriptionBox
                classes={classes}
                content='These rules ensure the postal code is in the correct format based on the country. For example, you can enforce the format of US postal codes to be ##### and Canada postal codes to be A#A #A#.'
                title='What are these postal code checks?'
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Country - Postal Code Validation Checks</h3>
                </CardHeader>
                <CardBody className={this.props.classes.center}>
                  <GridContainer justify='center'>
                    {_.map(this.state.country_zip_filters.sort(), (obj) => (
                      <GridItem xs={12} sm={6} md={4} lg={4} key={obj} style={{ marginBottom: 10 }}>
                        <DefaultCard className={classes.card}>
                          <CardContent>
                            <Typography variant='h5'>{obj.split(',')[1]}</Typography>
                            <Typography component='i'>{obj.split(',')[3] || 'Invalid Postal Code Format'}</Typography>
                            {
                              _.map(obj.split(',')[2].split('|'), (rule, index) => (
                                <div key={rule} style={{ marginTop: 15 }}>
                                  <Typography className={classes.pos} component='b'><b>Format #{index + 1}</b></Typography>
                                  <Typography className={classes.pos} component='i'>{rule.split('_')[0]} characters long</Typography>
                                  <Typography variant='body1'>{rule.split('_')[1]}</Typography>
                                </div>
                              ))
                            }
                          </CardContent>
                          <CardActions>
                            <Button size='small' style={{ margin: '0px auto' }} onClick={() => this.editRule(obj)}>Edit</Button>
                            <Button size='small' style={{ margin: '0px auto' }} onClick={() => this.setState({ deleteRuleForm: true, targetRule: obj })}>Remove</Button>
                          </CardActions>
                        </DefaultCard>
                      </GridItem>
                    ))}
                  </GridContainer>
                  <Fab variant='extended' aria-label='Add Country Postal Code Rule' color='primary' className={this.props.classes.button} onClick={() => this.setState({ showRuleForm: true, ruleFormMode: 'new', newCountry: '', newWarning: '', newOptions: 1, newRules: { option_1: { length: '', rules: [] } } })} style={{ margin: 15 }}>
                    Add Country Postal Code Rule
                  </Fab>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Dialog
            fullWidth={true}
            maxWidth='sm'
            open={this.state.deleteRuleForm}
            onClose={() => this.setState({ deleteRuleForm: false, targetRule: null })}
            aria-labelledby='delete-rule-form'
          >
            <DialogTitle id='delete-rule-form'>Are you sure?</DialogTitle>
            <DialogContent>
              Do you want to remove the postal code validation rules for <b>{this.state.targetRule ? this.state.targetRule.split(',')[1] : ''}</b>?
            </DialogContent>
            <DialogActions>
              <Button aria-label='Cancel' color='default' className={this.props.classes.button} onClick={() => this.setState({ deleteRuleForm: false, targetRule: null })} style={{ marginRight: 10 }}>
                No, Cancel
              </Button>
              <Fab variant='extended' aria-label='Remove' color='secondary' className={this.props.classes.button} onClick={() => this.removeRule(this.state.targetRule)}>
                Yes, Remove Rule
              </Fab>
            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth={true}
            maxWidth='md'
            open={this.state.showRuleForm}
            onClose={() => this.setState({ showRuleForm: false, newCountry: '', newOptions: 1, newRules: { option_1: { length: '', rules: [] } }, newWarning: '' })}
            aria-labelledby='postal-code-check-form'
          >
            <DialogTitle id='postal-code-check-form'>{this.state.ruleFormMode === 'new' ? 'Add new country postal code rule' : `Edit postal code validation rules for ${this.state.newCountry[1]} `}</DialogTitle>
            <DialogContent>
              <GridContainer justify='center'>
                {
                  this.state.ruleFormMode === 'new'
                  ?
                  <GridItem xs={6} sm={6} md={4}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor='newCountry'>Country</InputLabel>
                      <Select
                        value={this.state.newCountry}
                        onChange={(event) => this.setState({ newCountry: event.target.value })}
                      >
                        <MenuItem value={''}>
                          <em>Select Country</em>
                        </MenuItem>
                        {_.map(this.state.countries_list.sort(), (country) => <MenuItem value={country} key={country}>{country[1]}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </GridItem>
                  :
                  null
                }
                {
                  this.state.newCountry
                  ?
                  <GridItem xs={6} sm={6} md={5}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor='newCountry'>How many formats are valid?</InputLabel>
                      <Select
                        value={this.state.newOptions}
                        onChange={(event) => this.setState({ newOptions: event.target.value, newRules: this.initiateRules(event.target.value) })}
                      >
                        {_.map(_.range(1, 10), (count) => <MenuItem value={count} key={count}>{count}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </GridItem>
                  :
                  null
                }
              </GridContainer>
              <GridContainer justify='center'>
                {
                  this.state.newCountry
                  ?
                  <GridItem xs={6} sm={6} md={6}>
                    <TextField
                      id='newMessage'
                      label={`Customize warning displayed when rule is violated`}
                      value={this.state.newWarning || ''}
                      onChange={(event) => this.setState({ newWarning: event.target.value })}
                      className={classes.textField}
                      margin='normal'
                      fullWidth={true}
                    />
                  </GridItem>
                  :
                  null
                }
              </GridContainer>
              <GridContainer justify='center'>
                {
                  this.state.newCountry 
                  ?
                  _.map(_.range(1, this.state.newOptions + 1), (option) => {
                    return (
                      <GridItem xs={12} sm={12} md={10}  key={option} style={{ marginTop: 20 }}>
                        <Typography align='center' variant='subheading'>Valid format #{option}</Typography>
                        <GridContainer justify='center'>
                          <GridItem xs={6} sm={6} md={5}>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor='newLength'>What is the required length?</InputLabel>
                              <Select
                                value={this.state.newRules['option_' + option].length}
                                onChange={(event) => this.updateRuleLength(option, event.target.value) }
                              >
                                <MenuItem value=''>
                                  <em>Select Length</em>
                                </MenuItem>
                                {_.map(_.range(1,15), (num) => <MenuItem value={num} key={num}>{num}</MenuItem>)}
                              </Select>
                            </FormControl>
                          </GridItem>
                          {
                            this.state.newRules['option_' + option].length !== ''
                            ?
                            <GridItem xs={6} sm={6} md={5}>
                              {_.map(_.range(1, this.state.newRules['option_' + option].length + 1), (num) => (
                                <FormControl className={classes.formControl} key={num}>
                                  <InputLabel htmlFor='newRules'>Character #{num} Requirement</InputLabel>
                                  <Select
                                    value={this.state.newRules['option_' + option].rules[num - 1]}
                                    onChange={(event) => this.updateRule(option, num - 1, event.target.value)}
                                  >
                                    <MenuItem value=''>
                                      <em>Select Rule</em>
                                    </MenuItem>
                                    <MenuItem value='[A-Z]'>Alphabetic</MenuItem>
                                    <MenuItem value='[A-Z0-9]'>Alphabetic or Numeric</MenuItem>
                                    <MenuItem value='[0-9]'>Numeric</MenuItem>
                                    <MenuItem value=' '>Space</MenuItem>
                                    <MenuItem value='-'>-</MenuItem>
                                  </Select>
                                </FormControl>
                              ))}
                            </GridItem>
                            :
                            null
                          }
                        </GridContainer>
                      </GridItem>
                    );
                  })
                  :
                  null
                }
              </GridContainer>
            </DialogContent>>
            <DialogActions>
              <Button aria-label='Cancel' color='default' className={this.props.classes.button} onClick={() => this.setState({ showRuleForm: false })} style={{ marginRight: 10 }}>
                Cancel
              </Button>
              <Fab variant='extended' aria-label='Save' color='primary' className={this.props.classes.button} disabled={!this.allowSave()} onClick={() => this.saveRule()}>
                {this.state.ruleFormMode === 'new' ? 'Add' : 'Edit'} Postal Code Rule
              </Fab>
            </DialogActions>
          </Dialog>
        </div>
      );
  }
}

export default withStyles(PostalCodeChecks)(PostalCode);