import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import General from '../helper/general.js';
import Button from 'components/CustomButtons/Button.jsx';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import headerLinksStyle from '../assets/jss/headerLinks.jsx';
import Instructions from './instructions.jsx';


class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  componentDidMount() {
    const brand = General.getBrandData();
  }

  render() {
    const { classes } = this.props;
    
    return (
      <div>
        <Button color='transparent' aria-label='Show Instructions' className={classes.buttonLink} onClick={() => this.setState({ open: true })}>
          <MenuBookIcon className={classes.icons} />
          <span className={classes.linkText}>Code Snippet</span>
        </Button>
        <Instructions open={this.state.open} softClose={() => this.setState({ open: false })} hardClose={() => this.setState({ open: false })} />
      </div>
      :
      null
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
