import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import AddressValidatorApp from 'app/address_validator.jsx';
import 'assets/css/main.css';
import { unregister as unregisterServiceWorker } from './serviceWorker';
import { StripeProvider } from 'react-stripe-elements';


unregisterServiceWorker();


ReactDOM.render(
  <StripeProvider apiKey={process.env.NODE_ENV === 'development' ? 'pk_test_xwSbJSzSCVYKo1E5NYxgRYMt00TUDVYJtc' : 'pk_live_LcmDMb6q6SSQ9tlCyiSztGpC00LT536xmm'}>
    <Router history={createBrowserHistory()}>
      <Switch>        
        <Route path='/' component={AddressValidatorApp} />;
      </Switch>
    </Router>
  </StripeProvider>,
  document.getElementById('root')
);



// http://localhost:3001/address_validator?c2hvcD1pM2Mxa2x2Yzh0JmFwcD1hZGRyZXNzX3ZhbGlkYXRvciZqd3Q9ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnVZVzFsSWpvaWFUTmpNV3RzZG1NNGRDSXNJbWxoZENJNk1UWXlOemswT1RFMU1Dd2laWGh3SWpveE5qSTRNamswTnpVd2ZRLjhrV0tyMlJFbVNNdGl0ODRsa08tXzczSkhnd1IxU1V0UVp6LS1oYVg4Slk=