import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core/';
import General from 'helper/general.js';
import { NavLink } from 'react-router-dom';


class Pause extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suspended: false
    }
  }

  componentDidMount() {
    const brand = General.getBrandData();
    this.setState({ suspended: brand.suspended });
  }


  render() {
    if(this.state.suspended)
      return (
        <NavLink to={'/billing'}>
          <AppBar position='relative' color='secondary' onClick={() => this.setState({ open: true })} style={{ cursor: 'pointer' }}>
            <Toolbar>
              <Typography variant='h6' color='inherit' style={{ textAlign: 'center', flexGrow: 1 }}>
                <span role='img' aria-label='Alert'>&#128276;</span> Address Validation has been paused - input credit card here to resume service <span role='img' aria-label='Alert'>&#128276;</span>
              </Typography>
            </Toolbar>
          </AppBar>
        </NavLink>
      );
    else
      return null;
  }
};


export default Pause;