import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core/';
import General from 'helper/general.js';
import Instructions from './instructions.jsx';


class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBanner: true, 
      open: false,
      message_open: false,
      message: '',
      configured: false,
      stencil: false,
    }
  }

  componentDidMount() {
    const brand = General.getBrandData();
    this.setState({ checkout: brand.checkout, configured: brand.configured, stencil: brand.stencil });
  }

  copyCode() {
    const codeBox = document.getElementById('codeBox');

    let textarea = document.createElement('textarea');
    textarea.id = 'temp_element';
    textarea.style.height = 0;
    codeBox.appendChild(textarea);
    textarea.value = codeBox.innerText;
  
    document.querySelector('#temp_element').select();
    document.execCommand('copy');
    codeBox.removeChild(textarea);

    this.setState({ message_open: true, message: 'Code snippet copied to clipboard' });
  }


  render() {
    if((this.state.checkout !== 'optimized' || !this.state.stencil) && this.state.showBanner && !this.state.configured)
      return (
        <div>
          <Instructions open={this.state.open} softClose={() => this.setState({ open: false })} hardClose={() => this.setState({ open: false, showBanner: false })} />
          <AppBar position='relative' color='primary' onClick={() => this.setState({ open: true })} style={{ cursor: 'pointer' }}>
            <Toolbar>
              <Typography variant='h6' color='inherit' style={{ textAlign: 'center', flexGrow: 1 }}>
                <span role='img' aria-label='Megaphone'>&#128227;</span> Finalize setup by inserting code snippet - Click here for instructions <span role='img' aria-label='Megaphone'>&#128227;</span>
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
      );
    else
      return null;
  }
};


export default Notification;