import React from 'react';
import axios from 'axios';
import General from 'helper/general.js';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core/';


class AdminInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      admin_email: null,
      admin_phone: null,
      admin_name: null,
      adminInfoOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      admin_email: this.props.brandData.admin_email,
      admin_phone: this.props.brandData.admin_phone,
      admin_name: this.props.brandData.admin_name,
      adminInfoOpen: this.props.brandData.admin_email == null
    })
  }

  updateAdminInfo(event) {
    event.preventDefault();
    this.setState({ adminInfoOpen: false });

    const adminInfo = {
      admin_name: this.state.admin_name,
      admin_email: this.state.admin_email,
      admin_phone: this.state.admin_phone
    };

    axios({
      method: 'PUT',
      url: General.serverURL + this.props.app + '/bc_api/brand_attributes',
      data: Object.assign(adminInfo, { name: this.props.brandData.name }),
      headers: { Authorization: `Bearer ${General.getJWT()}` }
    })
    .then((result) => {
      this.props.cb('success');
      General.storeBrandData(Object.assign(this.props.brandData, adminInfo));
    })
    .catch((err) => this.props.cb('error'));
  }

  render() {
    return (
      <Dialog
        open={this.state.adminInfoOpen}
        onClose={() => this.setState({ adminInfoOpen: false })}
        aria-labelledby='admin-info-modal'
      >
        <DialogTitle id='admin-info-modal-title'>Admin Contact Info</DialogTitle>
        <form onSubmit={(event) => this.updateAdminInfo(event)}>
          <DialogContent>
            <DialogContentText>
              Specify admin contact info to stay updated on new features and changes.
            </DialogContentText>
            <TextField
              autoFocus
              margin='dense'
              id='admin-info-modal-name'
              label='Name'
              type='name'
              fullWidth
              value={this.state.admin_name || ''}
              onChange={(event) => this.setState({ admin_name: event.target.value })}
            />
            <TextField
              margin='dense'
              id='admin-info-modal-email'
              label='Email'
              type='email'
              fullWidth
              value={this.state.admin_email || ''}
              onChange={(event) => this.setState({ admin_email: event.target.value })}
            />
            <TextField
              margin='dense'
              id='admin-info-modal-phone'
              label='Phone'
              type='phone'
              fullWidth
              value={this.state.admin_phone || ''}
              onChange={(event) => this.setState({ admin_phone: event.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ adminInfoOpen: false })} color='primary'>
              Close
            </Button>
            <Button disabled={!this.state.admin_email} color='primary' variant='contained' type='submit'>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
};


export default AdminInfo;