import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { Button, Checkbox, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core/';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from 'axios';
import Loader from 'components/loader.jsx';
import EmailsStyles from 'assets/jss/emails.jsx';
import MoreInfo from '../../components/moreInfo.jsx';
import InputChips from '../../components/inputChips.jsx';
import DescriptionBox from 'components/descriptionBox.jsx';
import Snackbar from '../../components/snackBar.jsx';

const validateEmail = (email) => {
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};


class Emails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      open: false,
      email_address_incorrect: null,
      config_email_address_incorrect_customer: null,
      config_email_address_incorrect: null,
      custom_email_message: null,
      subjectUnverifiableAddress: null,
      subjectMissingUnitApt: null,
      emailHeaderLine: null,
      testEmail: '',
      testType: false
    };
  }

  componentDidMount() {
    const brand = General.getBrandData();

    this.setState({
      loading: false,
      email: brand.email,
      email_address_incorrect: brand.email_address_incorrect,
      config_email_address_incorrect_customer: brand.config_email_address_incorrect_customer,
      config_email_address_incorrect: brand.config_email_address_incorrect,
      custom_email_message: brand.custom_email_message,
      source_email: brand.source_email, 
      source_email_ready: brand.source_email_ready,
      source_email_enabled: brand.source_email_enabled,
      subjectUnverifiableAddress: brand.customization.subjectUnverifiableAddress,
      subjectMissingUnitApt: brand.customization.subjectMissingUnitApt,
      emailHeaderLine: brand.customization.emailHeaderLine,
    })
  }

  sendTestEmail() {
    axios({
      method: 'POST',
      url: General.serverURL + 'address_validator/bc_api/send_test_email',
      data: {
        brand_id: General.getBrandData().id,
        type: this.state.testType,
        email: this.state.testEmail
      }
    })
    .then((result) => this.setState({ alert_type: 'success', open: true, testEmail: '' }));
  }

  describeEmailTargets() {
    if(this.state.config_email_address_incorrect && this.state.config_email_address_incorrect_customer)
      return 'Emails will be sent to the store and customer.'
    else if(this.state.config_email_address_incorrect)
      return 'Emails will only be sent to the store.'
    else if(this.state.config_email_address_incorrect_customer)
      return 'Emails will only be sent to the customer.'
    else
      return 'No emails will be sent.'
  }

  changeEmailWho(key, store_value, customer_value) {
    this.setState({ config_email_address_incorrect_customer: customer_value, config_email_address_incorrect: store_value, open: false });

    General.setBrandValue(
      key, 
      key === 'config_email_address_incorrect' ? store_value : customer_value, 
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  updateState(key, value) {
    let newState = this.state;
    newState[key] = value;
    newState.open = false;
    this.setState(newState);
  }

  changeTextValue(event, key, message) {
    this.updateState(key, event.target.value);

    General.changeText(
      event,
      key, 
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  toggleOption(key, type) {
    this.updateState(key, !this.state[key]);

    General.setBrandValue(
      key,
      this.state[key], 
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  customizeEmail(event) {
    this.updateState('custom_email_message', event)

    General.changeText(
      event, 
      'custom_email_message', 
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  verifySourceEmail() {
    this.setState({ source_email_loading: true, source_email_loading_message: 'Hang tight - sending verification email to ' + this.state.source_email });
    axios({
      method: 'PUT',
      url: General.serverURL + 'address_validator/bc_api/initalize_source_email',
      headers: { Authorization: `Bearer ${General.getJWT()}` },
      data: {
        brand_id: General.getBrandData().id,
        source_email: this.state.source_email
      }
    })
    .then((result) => this.setState({ source_email_loading: false, source_email_initialized: true }));
  }

  confirmSourceEmail() {
   this.setState({ source_email_loading: true, source_email_loading_message: 'Confirming source email status' });
    axios({
      method: 'PUT',
      url: General.serverURL + 'address_validator/bc_api/check_status_source_email',
      headers: { Authorization: `Bearer ${General.getJWT()}` },
      data: {
        brand_id: General.getBrandData().id,
        source_email: this.state.source_email
      }
    })
    .then((result) => {
      if(result.data === 'verified')
        this.setState({ source_email_ready: true, source_email_loading: false });
      else if(result.data === 'pending')
        this.setState({ source_email_notice: true, source_email_loading: false });
    }); 
  }

  renderSourceEmailBody(classes) {
    if(this.state.source_email_ready)
      return (
        <CardBody className={classes.center}>
          <p className={classes.cardDescription}>
            Emails will be sent from <b>{this.state.source_email}</b>
          </p>
        </CardBody>
      );
    else
      return (
        this.state.source_email_loading
        ?
        <Loader marginBottom={50} marginTop={50} message={this.state.source_email_loading_message} />
        :
        <CardBody className={classes.center}>
          <p className={classes.cardDescription}>
            Enable to send email notifications from your own domain instead of <i>orders@roboturk.co</i>. <b>This feature will cost an additional $14.99 per month and will be added to your BigCommerce bill.</b>
            <MoreInfo note='Emails sent from your own email address may mitigate customer confusion and ensure a more seamless customer experience.' />
          </p>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.source_email_enabled}
                onChange={(value) => this.toggleOption('source_email_enabled', 'Custom email domain')}
                color='primary'
              />
            }
            label={this.state.source_email_enabled ? 'Enabled' : 'Disabled'}
          />
          {
            this.state.source_email_enabled
            ?
            <GridContainer justify='center'>
              <GridItem xs={12} md={8}>
                <p className={classes.cardDescription}><b>Step 1:</b> What email address do you want these notifications to be sent from?</p>
                <TextField
                  id='custom-email-address'
                  label='Source email'
                  value={this.state.source_email || ''}
                  onChange={(event) => this.setState({ source_email: event.target.value})}
                  className={classes.textField}
                  margin='normal'
                  disabled={this.state.source_email_initialized}
                  fullWidth={true}
                />
              </GridItem>
              {
                this.state.source_email && validateEmail(this.state.source_email)
                ?
                <GridItem xs={12} md={8}>
                  <p className={classes.cardDescription}><b>Step 2:</b> Verify email</p>
                  <Button variant='contained' color='primary' className={classes.button} disabled={this.state.source_email_initialized} onClick={() => this.verifySourceEmail()} style={{ marginTop: 10 }}>
                    Send verification email
                  </Button>
                </GridItem>
                :
                null
              }
              {
                this.state.source_email_initialized
                ?
                <GridItem xs={12}>
                  <p className={classes.cardDescription}><b>Step 3:</b> Check your <i>{this.state.source_email}</i> inbox for an email from <i>verification@roboturk.co</i> with a subject line of <i>[Address Validator app]: Please confirm your email</i>. Open the email and click on the link to confirm your identity. Once you do that, click the button below to finish setup.</p>
                  <Button variant='contained' color='primary' className={classes.button} onClick={() => this.confirmSourceEmail()} style={{ marginTop: 10 }}>
                    I have confirmed my email via the link
                  </Button>
                </GridItem>
                :
                null
              }
              {
                this.state.source_email_notice
                ?
                <b style={{ color: '#8B0000' }}>Pending - please check your email and click on the confirmation link</b>
                :
                null
              }
            </GridContainer>
            :
            null
          }
        </CardBody>
      );
  }


  render() {
    const { classes } = this.props;

    if(this.state.loading)
      return <Loader />;
    else
    return (
      <GridContainer justify='center'>
        <Snackbar
          alert_type={this.state.alert_type}
          close={() => this.setState({ open: false, alert_type: null })}
          open={this.state.open}
        />
        <GridItem xs={12} md={10}>
          <DescriptionBox
            classes={classes}
            content='<i>Address Validator</i> notifies customers of inconsistencies in shipping addresses during the standard checkout process. However, some customers may miss or inadvertently skip over these prompts and alerts, resulting in orders with unverifiable shipping addresses. You can automatically send out emails to alert the store and/or the customer of these scenarios.'
            title='What are these email notifications?'
          />
        </GridItem>
        <GridItem xs={12} md={10}>
          <Card>
            <CardHeader color='primary'>
              <h3 className={classes.cardTitleWhite}>Preview</h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify='center'>
                <GridItem xs={12} md={7}>
                  <GridContainer justify='center'>
                    <GridItem xs={12}>
                      <h3 style={{ margin: 0 }}>
                        {
                          this.state.emailHeaderLine
                          ||
                          (
                            this.state.testType
                            ?
                            this.state.subjectMissingUnitApt
                            :
                            this.state.subjectUnverifiableAddress
                          )
                        }
                      </h3>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      {
                        draftToHtml(convertToRaw(this.state.custom_email_message.getCurrentContent())).length > 8 
                        ?
                        <p dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(this.state.custom_email_message.getCurrentContent())) }} />
                        :
                        <p>Uh oh! We received your order, but we're not able to confidently verify your shipping address.<br /><br /><b>Please confirm or send an updated shipping address to {this.state.email_address_incorrect || this.state.email}</b><br /><br /></p>
                      }
                      <br/>
                      <span>Order: [order_id]</span>
                      <br />
                      <span>[first_name] [last_name]</span>
                      <br />
                      <span>[email]</span>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <a href='https://www.google.com/maps/place/40.73061,-73.935242' target='_blank' rel='noopener noreferrer'>
                        <img alt='map' src='https://image.maps.api.here.com/mia/1.6/mapview?c=40.73061,-73.935242&z=17.1&w=250&h=250&f=1&app_id=iYsJ7c228mivEUt7lUZN&app_code=jVdGwlZ9oywJzrQ8uqgSSw&poix0=40.73061,-73.935242;1aaf07;ffffff;20;o'/>
                      </a>
                      <div style={{ backgroundColor: '#404040', padding: 9, color: '#f2f2f2', textAlign: 'center', width: 232 }}>
                        <span>[address1]</span> <span>[address2]</span>
                        <br />
                        <span>[city]</span>, <span>[state]</span> <span>[zip]</span>
                        <br />
                        <span>[country]</span>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} md={3}>
                  <div className={classes.center}>
                    <Typography variant='h6'>Send test email</Typography>
                    <p className={classes.cardDescription}>Where do you want to receive this test email?</p>
                    <TextField
                      id='test-email-addresses'
                      label='Email address'
                      value={this.state.testEmail}
                      onChange={(event) => this.setState({ testEmail: event.target.value})}
                      className={classes.textField}
                      margin='normal'
                      fullWidth={true}
                    />
                    <p className={classes.cardDescription}>What type of email notification?</p>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.testType}
                          onChange={(value) => this.setState({ testType: !this.state.testType })}
                          color='primary'
                        />
                      }
                      label={this.state.testType ? 'Missing apt/suite #' : 'Invalid address'}
                    />
                    <br />
                    <Button variant='contained' color='primary' className={classes.button} disabled={!this.state.testEmail} onClick={() => this.sendTestEmail()}>
                      Send test email
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} md={5}>
          <Card>
            <CardHeader color='primary'>
              <h3 className={classes.cardTitleWhite}>Custom email domain</h3>
            </CardHeader>
            {this.renderSourceEmailBody(classes)}
          </Card>
        </GridItem>
        <GridItem xs={12} md={5}>
          <Card>
            <CardHeader color='primary'>
              <h3 className={classes.cardTitleWhite}>Who gets these emails?</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={6}>
                   <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.config_email_address_incorrect}
                        onChange={(event) => this.changeEmailWho('config_email_address_incorrect', event.target.checked, this.state.config_email_address_incorrect_customer)}
                        value='Store'
                        color='primary'
                      />
                    }
                    label='Store'
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.config_email_address_incorrect_customer}
                        onChange={(event) => this.changeEmailWho('config_email_address_incorrect_customer', this.state.config_email_address_incorrect, event.target.checked)}
                        value='Customer'
                        color='primary'
                      />
                    }
                    label='Customer'
                  />
                </GridItem>
                <GridItem xs={12}>
                  <p className={classes.cardDescription}>
                    {this.describeEmailTargets()}
                  </p>
                </GridItem>
                <GridItem xs={12}>
                  <p className={classes.cardDescription}>
                    <b>Store email to receive notifications</b>
                    <br />
                    The email notifications will go to <i>{General.getBrandData().email}</i> if no other email is specified below.
                  </p>
                  <InputChips list={this.state.email_address_incorrect} label='Email address' onChange={(value) => this.changeTextValue(value, 'email_address_incorrect', 'Automated emails will be sent to')}/>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} md={5}>
          <Card>
            <CardHeader color='primary'>
              <h3 className={classes.cardTitleWhite}>Subject lines</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <TextField
                    id='invalid-addresses-subject'
                    label='Subject line for invalid addresses'
                    value={this.state.subjectUnverifiableAddress || 'Please confirm or update shipping address'}
                    onChange={(event) => this.changeTextValue(event, 'subjectUnverifiableAddress', 'Subject line updated to ')}
                    className={classes.textField}
                    margin='normal'
                    fullWidth={true}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextField
                    id='missing-num-subject'
                    label='Subject line for missing apt/suite #'
                    value={this.state.subjectMissingUnitApt || 'Please specify apt, unit, suite or floor'}
                    onChange={(event) => this.changeTextValue(event, 'subjectMissingUnitApt', 'Subject line updated to ')}
                    className={classes.textField}
                    margin='normal'
                    fullWidth={true}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} md={10}>
          <Card>
            <CardHeader color='primary'>
              <h3 className={classes.cardTitleWhite}>Email template</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={4}>
                  <p className={classes.cardDescription}>
                    Specify the email header. It will the same as the subject line if nothing is specified.
                  </p>
                  <TextField
                    id='email-header'
                    label='Header line on email body'
                    value={this.state.emailHeaderLine || ''}
                    onChange={(event) => this.changeTextValue(event, 'emailHeaderLine', 'Header line updated to ')}
                    className={classes.textField}
                    multiline
                    margin='normal'
                    fullWidth={true}
                  />
                </GridItem>
                <GridItem xs={12} md={8}>
                  <p className={classes.cardDescription}>
                    Specify the email message.
                  </p>
                  <Editor
                    editorState={this.state.custom_email_message}
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassName'
                    placeholder='Customize email message here'
                    onEditorStateChange={(event) => this.customizeEmail(event)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(EmailsStyles)(Emails);
