import _ from 'lodash';
import axios from 'axios';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';

const DEV = process.env.NODE_ENV === 'development'; // localstorage is only available in development since it cannot be used within an iframe
const serverURL = DEV ? 'http://localhost:3000/' : 'https://app.roboturk.co/';
let updateCustomTextTimeout; // Used to store timeout function to delay updates based on text changes

let brandData = DEV ? JSON.parse(localStorage.getItem('address_validator_data')) : null;

let ajaxJWT = DEV && brandData ? localStorage.getItem('av_put_jwt_' + brandData.name) : null;

const saveChanges = (changes, cb) => {
  axios({
    method: 'PUT',
    url: serverURL + 'address_validator/bc_api/brand_attributes',
    data: Object.assign(changes, { name: brandData.name }),
    headers: { Authorization: `Bearer ${ajaxJWT}` }
  })
  .then((result) => cb('success'))
  .catch((err) => cb('error'));
};

const setStylesheet = () => {
  const stylesheet = document.getElementById('custom_stylesheet');
  const css = `
    #suggestedAddress:hover, #originalAddress:hover, button.av_button:hover { 
      background-color: ${brandData.customization.colorHover} !important;
    }
    p#loader-text, span#input-address {
      color: ${brandData.customization.colorText};
    }
    ${brandData.style_css}
  `;

  if(stylesheet)
    stylesheet.innerHTML = css;
  else {
    let style = document.createElement('style');
    style.setAttribute('id', 'custom_stylesheet');
    if(style.styleSheet)
      style.styleSheet.cssText = css;
    else
      style.appendChild(document.createTextNode(css));
    document.getElementsByTagName('head')[0].appendChild(style);
  }
};
const stringifyConvertRaw = (input) => JSON.stringify(convertToRaw(input.getCurrentContent()));


export default {
  setJWT(data, shop) {
    ajaxJWT = data;
    if(DEV)
      localStorage.setItem(`av_put_jwt_${shop}`, data);
  },
  getJWT: () => ajaxJWT,


  serverURL: serverURL,

  setStylesheet: setStylesheet,

  storeBrandData(data) {
    brandData = data;
    if(DEV)
      localStorage.setItem('address_validator_data', JSON.stringify(data));
  },

  getBrandData() { return brandData },

  setBrandValue(key, value, cb) {
    if(brandData[key] !== value) {
      brandData[key] = value;
      saveChanges(_.pick(brandData, key), cb);
    }
  },

  changeColor(color, attribute, cb) {
    if(brandData.customization[attribute] !== color.hex) {
      brandData.customization[attribute] = color.hex;
      saveChanges(_.pick(brandData, 'customization'), cb);
      setStylesheet();
    }
  },

  changeText(event, attribute, cb) {
    clearTimeout(updateCustomTextTimeout);

    if(['style_css', 'email_address_incorrect', 'skip_keywords', 'update_link_email', 'po_box_scope'].indexOf(attribute) !== -1) {
      if(brandData[attribute] !== event.target.value) {
        brandData[attribute] = event.target.value;

        if(attribute === 'style_css')
          setStylesheet();

        updateCustomTextTimeout = setTimeout(() => saveChanges(_.pick(brandData, attribute), cb), attribute === 'po_box_scope' ? 100 : 1500);
      }
    }
    else if(attribute === 'custom_email_message') {

      if(stringifyConvertRaw(brandData[attribute]) !== stringifyConvertRaw(event)) {
        const rawData = convertToRaw(event.getCurrentContent());
        let customEmailMessageValue;

        // Check to account for empty custom message
        if(rawData.blocks.length === 1 && rawData.blocks[0].text === '') {
          brandData.customization.incorrectAddressEmailBody = null;
          customEmailMessageValue = null;
        }
        else {
          brandData.customization.incorrectAddressEmailBody = draftToHtml(rawData);
          customEmailMessageValue = stringifyConvertRaw(event);
        }

        updateCustomTextTimeout = setTimeout(() => saveChanges({ customization: brandData.customization, custom_email_message: customEmailMessageValue }, cb), 1500);
      }

      brandData[attribute] = event;
    }
    else if(brandData.customization[attribute] !== event.target.value) {
      brandData.customization[attribute] = event.target.value;

      updateCustomTextTimeout = setTimeout(() => saveChanges(_.pick(brandData, 'customization'), cb), 1500);
    }
  }
};