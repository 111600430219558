import AlertIcon from '@material-ui/icons/Warning';
import EmailsIcon from '@material-ui/icons/Email';
import OrderHistoryIcon from '@material-ui/icons/History';
import PromptsIcon from '@material-ui/icons/Message';
import BillingIcon from '@material-ui/icons/CreditCard';
import PostalCodeRulesIcon from '@material-ui/icons/Map';
import AnalyticsIcon from '@material-ui/icons/Timeline';

import Alerts from 'views/address_validator/Alerts.jsx';
import Analytics from 'views/address_validator/Analytics.jsx';
import Emails from 'views/address_validator/Emails.jsx';
import OrderHistory from 'views/address_validator/OrderHistory.jsx';
import Prompts from 'views/address_validator/Prompts.jsx';
import Billing from 'views/address_validator/Billing.jsx';
import PostalCodeRules from 'views/address_validator/PostalCodeChecks.jsx';


const Routes = [
  {
    path: '/address_prompts',
    sidebarName: 'Address Prompts',
    navbarName: 'Address Prompts',
    icon: PromptsIcon,
    component: Prompts
  },
  {
    path: '/alerts',
    sidebarName: 'Address Alerts',
    navbarName: 'Address Alerts',
    icon: AlertIcon,
    component: Alerts
  },
  {
    path: '/email_notifications',
    sidebarName: 'Email Notifications',
    navbarName: 'Email Notifications',
    icon: EmailsIcon,
    component: Emails
  },
  {
    path: '/postal_code_validation',
    sidebarName: 'Postal Code Rules',
    navbarName: 'Postal Code Rules',
    icon: PostalCodeRulesIcon,
    component: PostalCodeRules
  },
  {
    path: '/analytics',
    sidebarName: 'Analytics',
    navbarName: 'Analytics',
    icon: AnalyticsIcon,
    component: Analytics
  },
  {
    path: '/order_history',
    sidebarName: 'Order History',
    navbarName: 'Order History',
    icon: OrderHistoryIcon,
    component: OrderHistory
  },
  {
    path: '/billing',
    sidebarName: 'Billing',
    navbarName: 'Billing',
    icon: BillingIcon,
    component: Billing
  },
  { 
    redirect: true, 
    path: '/', 
    to: '/address_prompts', 
    navbarName: 'Redirect' 
  }
];

export default Routes;
