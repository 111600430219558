import { container, defaultFont, warningColor } from 'assets/jss/theme.jsx';

const headerStyle = theme => ({
  appBar: {
    backgroundColor: warningColor,
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'absolute',
    width: '100%',
    paddingTop: '10px',
    zIndex: '1029',
    color: '#555555',
    border: '0',
    borderRadius: '3px',
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block'
  },
  container: {
    ...container,
    minHeight: '50px'
  },
  flex: {
    flex: 1
  },
  iconButton: {
    color: 'white'
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    fontWeight: 'bold',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'white',
    margin: '0',
  }
});

export default headerStyle;
