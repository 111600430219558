import { cardCategoryWhite, cardTitleWhite } from 'assets/jss/theme.jsx';


const AlertsStyle = theme => ({
  cardCategoryWhite,
  cardTitleWhite,
  cardDescription: {
    fontSize: '16px',
    marginBottom: '0px',
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  },
  formControl: {
    width: '100%'
  },
  colorTitle: {
    fontSize: '16px',
    marginTop: '10px',
    textAlign: 'center'
  },
  warningBox: {
    borderRadius: '5px',
    marginTop: '10px',
    padding: '10px',
    border: '1px solid rgb(229, 148, 0)',
    color: 'rgb(229, 148, 0)',
    backgroundColor: 'rgb(255, 246, 229)'
  },
  table: {
    cursor: 'pointer'
  },
  thead: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    cursor: 'default'
  },
  table_selected: {
    backgroundColor: '#81c784'
  },
  table_cell_green: {
    color: theme.palette.success.dark
  }
});

export default AlertsStyle;