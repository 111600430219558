/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Routes from 'routes/address_validator.jsx';
import axios from 'axios';
import { convertFromRaw, EditorState } from 'draft-js';
import Header from '../components/header.jsx';
import Loader from '../components/loader.jsx';
import Sidebar from '../components/sidebar.jsx';
import General from '../helper/general.js';
import Notification from '../components/notification.jsx';
import Pause from '../components/pause.jsx';
import mainStyle from 'assets/jss/main.jsx';
import moment from 'moment';

const addressValidatorPage = 'https://addressvalidator.com';


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: null,
      loading: true,
      mobileOpen: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  
  resizeFunction() {
    if(window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  pullBrandData(shop_name) {
    axios({
      method: 'GET',
      url: General.serverURL + 'address_validator/bc_api/admin_home?shop=' + shop_name
    })
    .then((result) => {
      const brand = result.data.brand;

      // Set rich text editor
      brand.custom_email_message = brand.custom_email_message ? EditorState.createWithContent(convertFromRaw(JSON.parse(brand.custom_email_message))) : EditorState.createEmpty();
      
      // store merchant data
      General.storeBrandData(brand);

      // Start Intercom
      window.intercomSettings = Object.assign(_.pick(brand, ['activated', 'admin_email', 'admin_name', 'admin_phone', 'configured']), {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'honm34jo',
        apphub_id: 'address_validator',
        name: brand.store_name,
        email: brand.email,
        company: brand.domain,
        user_id: "AV_" + brand.name,
        created_at: moment(brand.created_at).unix(), 
        type: 'BigCommerce'
      });

      this.setState({ loading: false, brand: brand });
    })
    .catch((err) => {
      console.log(err);
      console.log('brand fetch error');
      window.location = addressValidatorPage;
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeFunction);

    const existingBrand = General.getBrandData();

    // First time visiting URL
    if(this.props.location.search) {
      const url_params = this.props.location.search.replace('?', '');

      try {
        const urlString = atob(url_params).split('&');
        if(urlString.length === 3) {
          const shopPart = urlString[0].split('=');
          const appPart = urlString[1].split('=');
          const jwtPart = urlString[2].split('=');
          if(window.top === window.self && window.location.host !== 'localhost:3001')
            window.location = `https://store-${shopPart[1]}.mybigcommerce.com/manage/app/16401`;
          else if(shopPart[0] === 'shop' && appPart[0] === 'app' && appPart[1] === 'address_validator' && jwtPart[0] === 'jwt') {
            General.setJWT(jwtPart[1], shopPart[1]);
            this.pullBrandData(shopPart[1]);
          }
          else
            throw 'missing params';
        }
        else
          throw 'invalid length';
      }
      catch(err) {
        console.log(err);
        window.location = addressValidatorPage;
      }
    }
    // Refreshing from previous load
    else if(existingBrand)
      this.pullBrandData(existingBrand.name);
    else {
      console.log('no brand data and bad URL');
      window.location = addressValidatorPage;
    }
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  }

  render() {
    const { classes, ...rest } = this.props;

    if(this.state.loading)
      return <Loader />;
    else
      return (
        <div className={classes.wrapper}>
          <Notification />
          <Pause />
          <Sidebar
            routes={Routes}
            handleDrawerToggle={() => this.setState({ mobileOpen: !this.state.mobileOpen })}
            closeDrawer={() => this.setState({ mobileOpen: false })}
            open={this.state.mobileOpen}
            brand={this.state.brand}
            app='address_validator'
            {...rest}
          />
          <div className={classes.mainPanel} ref='mainPanel'>
            <Header
              routes={Routes}
              handleDrawerToggle={() => this.setState({ mobileOpen: !this.state.mobileOpen })}
              brand={this.state.brand}
              app='address_validator'
              {...rest}
            />
            <div className={classes.content}>
              <Switch>
                {Routes.map((prop, key) => (
                  (prop.redirect)
                  ?
                  <Redirect from={prop.path} to={prop.to} key={key} />
                  :
                  <Route path={prop.path} component={prop.component} key={key} />
                ))}
              </Switch>
            </div>
          </div>
        </div>
      );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(mainStyle)(Main);
