import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import { TextField, Typography } from '@material-ui/core/';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import PromptsStyles from 'assets/jss/prompts.jsx';
import Loader from 'components/loader.jsx';
import AdminInfo from '../../components/adminInfo.jsx';
import ColorPicker from '../../components/colorPicker.jsx';
import InputChips from '../../components/inputChips.jsx';
import MoreInfo from '../../components/moreInfo.jsx';
import Snackbar from '../../components/snackBar.jsx';
import Toggle from '../../components/toggle.jsx';
import 'assets/css/prompts.scss';
import DescriptionBox from 'components/descriptionBox.jsx';


const customText = [
  { bucket: 'suggestion', key: 'textSuggest1', label: 'Title', type: 'component' },
  { bucket: 'suggestion', key: 'textSuggest2', label: 'Subtitle', type: 'component' },
  { bucket: 'suggestion', key: 'textSuggestedAddress', label: 'Suggested address label', type: 'component' },
  { bucket: 'suggestion', key: 'textOriginalAddress', label: 'Original address label', type: 'component' },
  { bucket: 'suggestion', key: 'textPopupEdit', label: 'Edit/close button label', type: 'component' },
  { bucket: 'suggestion', key: 'textPopupAccept', label: 'Accept changes button label', type: 'component' },
  { bucket: 'suggestion', key: 'textSelectSuggestion', label: 'Suggested address option selected', type: 'prompt' },
  { bucket: 'suggestion', key: 'textSelectConfirm', label: 'Original address option selected', type: 'prompt' },
  { bucket: 'invalid', key: 'textInaccurate', label: 'Prompt when an inaccurate address is detected', type: 'prompt' },
  { bucket: 'invalid', key: 'textAptSuite', label: 'Prompt when the apt # is missing', type: 'prompt' },
  { bucket: 'invalid', key: 'textPopupThanks', label: 'Message when customer proceeds with unverifiable addresses', type: 'prompt' },
  { bucket: 'invalid', key: 'buttonUpdate', label: 'Update address button label', type: 'component' },
  { bucket: 'invalid', key: 'buttonProceed', label: 'Proceed button label', type: 'component' },
  { bucket: 'other', key: 'textLoading', label: 'Prompt when address is being validated', type: 'component' },
  { bucket: 'other', key: 'textMissingFields', label: 'Message when some required fields are missing', type: 'prompt' },
  { bucket: 'other', key: 'textCorrect', label: 'Message when a correct addresses is detected', type: 'prompt' },
];

const colors = [
 { key: 'colorTrim', label: 'Trim', conditional: true }, 
 { key: 'colorBackground', label: 'Background', conditional: false }, 
 { key: 'colorBorder', label: 'Border', conditional: false }, 
 { key: 'colorText', label: 'Text', conditional: false }, 
 { key: 'colorHighlight', label: 'Highlight Changes', conditional: false }, 
 { key: 'colorHover', label: 'Hover', conditional: false }, 
 { key: 'colorButtonBackground', label: 'Button Background', conditional: true }, 
 { key: 'colorButtonBorder', label: 'Button Border', conditional: true }, 
 { key: 'colorButtonText', label: 'Button Text', conditional: true }, 
];



class Prompts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      open: false
    };
  }

  updateState(key, value) {
    let newState = this.state;
    newState[key] = value;
    newState.open = false;
    this.setState(newState);
  }

  componentDidMount() {
    const brand = General.getBrandData();
    General.setStylesheet();

    this.setState(Object.assign(
      brand.customization, 
      _.pick(brand, ['usps_only', 'config_us_4_zip', 'hide_popup_thanks', 'loose_check', 'allow_popup_edit_button', 'skip_keywords', 'popup_accept_button', 'us_separate_address_2']),
      {
        style_css : brand.style_css || '',
        loading: false
      }
    ));
  }

  toggleOption(key, type) {
    this.updateState(key, !this.state[key]);
    General.setBrandValue(key, this.state[key], (type) => this.setState({ open: true, alert_type: type }));
  }

  changeColor(color, key) {
    this.updateState(key, color.hex);
    General.changeColor(color, key, (type) => this.setState({ open: true, alert_type: type }));
  }

  changeText(event, key) {
    this.updateState(key, event.target.value);
    General.changeText(event, key, (type) => this.setState({ open: true, alert_type: type }));
  }

  
  modalWrap(input) {
    return (
      <div id='myModal'>
        <div id='myModal-container' style={{ backgroundColor: this.state.colorTrim }}>
          {input}
        </div>
      </div>
    );
  }

  promptButtonStyle() {
    return { backgroundColor: this.state.colorButtonBackground, borderColor: this.state.colorButtonBorder, color: this.state.colorButtonText };
  }

  // Add address and buttons only if: (1) prompt is on the 1st step of checkout and (2) key is equal to textAptSuite or textInaccurate
  invalidAddressPrompts(key, input) {
    if(['textAptSuite', 'textInaccurate'].indexOf(key) !== -1)
      return (
        <div>
          {input}
          {
            <div>
              <br />
              <span id='input-address'>
                {key === 'textAptSuite' ? 'Apartment Building Address' : 'Nonexistent Address'}
                <br />
                San Francisco, CA, 94105
              </span>
              <br />
              <div className='btn-row'>
                <button className='av_button' id='btn-update-address' style={this.promptButtonStyle()}>{this.state.buttonUpdate}</button>
                <button className='av_button' id='btn-proceed-address' style={this.promptButtonStyle()}>{this.state.buttonProceed}</button>
              </div>
            </div>
          }
        </div>
      );
    else
      return input;
  }


  renderInputOrPreview(obj, type) {
    // Do not show if:
    // a) hide_popup_thanks && key IN [textCorrect, textPopupThanks]
    // b) !allow_popup_edit_button && key === textPopupEdit
    // c) !popup_accept_button && key === textPopupAccept
    if(
      (this.state.hide_popup_thanks && ['textCorrect', 'textPopupThanks'].indexOf(obj.key) !== -1) ||
      (!this.state.allow_popup_edit_button && obj.key === 'textPopupEdit') ||
      (!this.state.popup_accept_button && obj.key === 'textPopupAccept')
    )
      return null;
    else {
      if(type === 'preview')
        return (
          <div key={obj.key} style={{ width: '100%' }}>
            <Typography variant='subtitle1' className='preview-header-modal'>{obj.label}</Typography>
            {
              this.modalWrap(
              this.invalidAddressPrompts(
                obj.key,
                <div id='addressValidatorBox' style={{ backgroundColor: this.state.colorBackground, borderColor: this.state.colorBorder, color: this.state.colorText }}>
                  <h2>{this.state[obj.key]}</h2>
                </div>
              ))
            }
          </div>
        );
      else if(type === 'input')
        return (
          <GridItem xs={12} xl={6} key={obj.key}>
            <TextField
              key={obj.key}
              label={obj.label}
              multiline={true}
              InputLabelProps={{ shrink: true }}
              placeholder={''}
              value={this.state[obj.key]}
              onChange={(event) => this.changeText(event, obj.key)}
              margin='normal'
              fullWidth={true}
            />
          </GridItem>
        );
    }
  }


  render() {
    const { classes } = this.props;

    if(this.state.loading)
      return <Loader />;
    else
      return (
        <div>
          <Snackbar
            alert_type={this.state.alert_type}
            close={() => this.setState({ open: false, alert_type: null })}
            open={this.state.open}
          />
          <AdminInfo 
            cb={(type) => this.setState({ open: true, alert_type: type })} 
            brandData={General.getBrandData()}
            app='address_validator'
          />
          <GridContainer justifyContent='center'>
            <GridItem xs={12} md={10}>
              <DescriptionBox
                classes={classes}
                content='This is the 1<sup>st</sup> line of defense against shipping addresses with errors. Address prompts either: (a) offer suggested corrections or (b) warn customers of unverifiable addresses when suggestions are not available. For example, it would be impossible to suggest an alternative address for "123 Test Street".'
                title='What are these address prompts?'
              />
            </GridItem>
            <GridItem sm={12} md={10} lg={5}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>US address validation options</h3>
                </CardHeader>
                <CardBody className={classes.center}>
                  <p className={classes.cardDescription}>
                    Do you only ship with USPS? 
                    <MoreInfo note='UPS/FedEx can ship to some addresses USPS cannot. This option determines the appropriate data sources to use for validation.' />
                  </p>
                  <Toggle value={this.state.usps_only} onChange={() => this.toggleOption('usps_only')} yes_label='Yes' no_label='No' />
                  <hr />
                  <p className={classes.cardDescription}>
                    Do you want to ignore ZIP+4 codes?
                    <MoreInfo note='ZIP+4 codes are the last 4 digits of a 9-digit zipcode. While not required by USPS, these can help ensure the fastest and most accurate delivery possible.' />
                  </p>
                  <Toggle value={this.state.config_us_4_zip} onChange={() => this.toggleOption('config_us_4_zip')} yes_label='Yes' no_label='No' />
                  <hr />
                  <p className={classes.cardDescription}>
                    Do you need to separate the street address from the apt #?
                    <MoreInfo note='USPS formatting dictates that the apartment # follow the street address on the same field. However, some 3PLs require the two components in separate fields (address_1 and address_2).' />
                  </p>
                  <Toggle value={this.state.us_separate_address_2} onChange={() => this.toggleOption('us_separate_address_2')} yes_label='Yes' no_label='No' />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem sm={12} md={10} lg={5}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Advanced options</h3>
                </CardHeader>
                <CardBody className={classes.center}>
                  <p className={classes.cardDescription}>
                    Do you want to ignore proper address formatting?
                    <MoreInfo note='If enabled, no suggested changes will be shown if the only discrepancies are merely formatting, capitalizations or abbreviations. For example, "20 main st" would be equivalent to "20 Main Street" and no suggested changes will be shown. While proper formatting is not required for accurate delivery, it may be useful for other purposes.' />
                  </p>
                  <Toggle value={this.state.loose_check} onChange={() => this.toggleOption('loose_check')} yes_label='Yes' no_label='No' />
                  <hr />
                  <p className={classes.cardDescription}>
                    Specify list of keywords to skip address validation
                    <MoreInfo note='This allows you to bypass the validation process for test or in-store pickup orders. Whenever the address_1 or company fields contains any of the keywords, the address validation will be skipped. The keywords are not case sensitive.' />
                  </p>
                  <InputChips list={this.state.skip_keywords} label='Keyword to skip address validation' onChange={(value) => this.changeText(value, 'skip_keywords')}/>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem sm={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>General customizations</h3>
                </CardHeader>
                <CardBody className={classes.center}>
                  <GridContainer>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        {
                          _.map(colors, (colorObj) => (
                            <GridItem xs={12} md={4} key={colorObj.key}>
                              <Typography className={classes.cardDescription}>{colorObj.label}</Typography>
                              <ColorPicker color={this.state[colorObj.key]} onChangeComplete={ (color) => this.changeColor(color, colorObj.key) } />
                            </GridItem>
                          ))
                        }                     
                        <GridItem xs={12}>
                          <p className={classes.cardDescription}>
                            Do you want to hide messages when a customer either proceeds with an unverifiable address or specifies a correct address?
                          </p>
                          <Toggle value={this.state.hide_popup_thanks} onChange={() => this.toggleOption('hide_popup_thanks')} yes_label='Yes' no_label='No' />
                        </GridItem>
                        {_.map(_.filter(customText, (text) => text.bucket === 'other'), (data) => this.renderInputOrPreview(data, 'input'))}
                        <GridItem xs={12}>
                        <Typography className={classes.cardDescription}>Need some additional customization? Specify the custom CSS code here:</Typography>
                          <TextField
                            label='Custom CSS'
                            rows={5}
                            multiline={true}
                            InputLabelProps={{ shrink: true }}
                            placeholder={`Example: \n#addressValidatorBox {\n  font-family: Arial, Helvetica, sans-serif;\n  color: #32CD32;\n}`}
                            value={this.state.style_css}
                            onChange={(event) => this.changeText(event, 'style_css')}
                            className={this.props.classes.textField}
                            margin='normal'
                            fullWidth={true}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        <div style={{ width: '100%' }}>
                          <Typography variant='subtitle1' className={'preview-header-modal'}>Prompt when address is being validated</Typography>
                          {this.modalWrap(<div><p id='loader-text'>{this.state.textLoading}</p><div id='loader-spinner' /></div>)}
                        </div>
                        {_.map(_.filter(customText, (text) => text.bucket === 'other' && text.type === 'prompt'), (data) => this.renderInputOrPreview(data, 'preview'))}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem sm={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Customize suggested corrections prompt</h3>
                </CardHeader>
                <CardBody className={classes.center}>
                  <GridContainer>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        <GridItem xs={12} className={classes.center}>
                          <p className={classes.cardDescription}>
                            Do you want a button that allows customers to exit out of the prompt and specify a new shipping address?
                            <MoreInfo note='If disabled, customers can only confirm the existing address or choose the suggested address on the suggestion prompt.' />
                          </p>
                          <Toggle value={this.state.allow_popup_edit_button} onChange={() => this.toggleOption('allow_popup_edit_button')} yes_label='Yes' no_label='No' />
                          <p className={classes.cardDescription}>
                            Do you want a button that allows customers to accept the suggested changes?
                            <MoreInfo note='If enabled, customers can accept the corrected address via the button or clicking on the suggested address.' />
                          </p>
                          <Toggle value={this.state.popup_accept_button} onChange={() => this.toggleOption('popup_accept_button')} yes_label='Yes' no_label='No' />
                        </GridItem>
                        {_.map(_.filter(customText, (text) => text.bucket === 'suggestion'), (data) => this.renderInputOrPreview(data, 'input'))}
                      </GridContainer>
                    </GridItem>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        <div style={{ width: '100%' }}>
                          <Typography variant='subtitle1' className='preview-header-modal'>Suggested corrections prompt</Typography>
                          {this.modalWrap(
                          <div id='addressValidatorBox' style={{ backgroundColor: this.state.colorBackground, borderColor: this.state.colorBorder, color: this.state.colorText }}>
                            <h2>{this.state.textSuggest1}</h2>
                            <small>{this.state.textSuggest2}</small>
                            <br /><br />
                            <div id='suggestedAddress'>
                              <b><i>{this.state.textSuggestedAddress}</i></b>
                              <br />
                              <span className='changed' style={{ color: this.state.colorHighlight }}>400 Howard St</span><br /><span className='changed' style={{ color: this.state.colorHighlight }}>San Francisco</span>, <span>CA</span> <span>94105</span><span className='changed' style={{ color: this.state.colorHighlight }}>-2618</span>
                            </div>
                            <hr />
                            <div id='originalAddress'>
                              <b><i>{this.state.textOriginalAddress}</i></b>
                              <br />
                              <span>400 howard</span><br /><span>san francisco</span>, <span>CA</span> <span>94105</span>
                            </div>
                            {
                              this.state.allow_popup_edit_button || this.state.popup_accept_button
                              ?
                              <div className='btn-row'>
                                {this.state.allow_popup_edit_button ? <button className='av_button' id='btn-edit-address' style={this.promptButtonStyle()}>{this.state.textPopupEdit}</button> : null}
                                {this.state.popup_accept_button ? <button className='av_button' id='btn-accept-address' style={this.promptButtonStyle()}>{this.state.textPopupAccept}</button> : null}
                              </div>
                              :
                              null
                            }
                          </div>
                          )}
                        </div>
                        {_.map(_.filter(customText, (text) => text.bucket === 'suggestion' && text.type === 'prompt'), (data) => this.renderInputOrPreview(data, 'preview'))}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem sm={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Customize unverifiable addresses prompts</h3>
                </CardHeader>
                <CardBody className={classes.center}>
                  <GridContainer>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        {_.map(_.filter(customText, (text) => text.bucket === 'invalid'), (data) => this.renderInputOrPreview(data, 'input'))}
                      </GridContainer>
                    </GridItem>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        {_.map(_.filter(customText, (text) => text.bucket === 'invalid' && text.type === 'prompt'), (data) => this.renderInputOrPreview(data, 'preview'))}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}

export default withStyles(PromptsStyles)(Prompts);