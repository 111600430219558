import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Button, Snackbar } from '@material-ui/core/';
import axios from 'axios';
import General from 'helper/general.js';
import 'assets/css/stripe.css';


class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      message: null,
      open: false
    };
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    ev.preventDefault();
    this.setState({ disabled: true });
    if(this.props.stripe) {
      this.props.stripe.createToken().then((payload) => {
        if(payload.error)
          this.setState({ disabled: false, message: payload.error.message, open: true });
        else if(payload.token) {
          axios({
            method: 'POST',
            url: General.serverURL + 'address_validator/bc_api/activate_charges',
            data: { brand_id: General.getBrandData().id, token: payload.token.id }
          })
          .then((result) => {
            this.setState({ disabled: false });
            this.props.successFunc();  
          })
          .catch((err) => this.setState({ disabled: false, message: err.response.data, open: true }))
        }
      })
    } 
    else
      console.log('Stripe.js has not loaded yet.');
  }

  render() {
    return (
      <div className='checkout' style={{ textAlign: 'center' }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={() => this.setState({ open: false })}
          message={<span id='message-id'>{this.state.message}</span>}
        />
        <CardElement />
        <Button variant='contained' color='primary' onClick={this.submit} disabled={this.state.disabled}>
          Submit
        </Button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);